import BaseModel from '../core/BaseModel'
import PropertyModel from "@/models/ServiceModel";
import UserModel from "@/models/UserModel";
export default class PaymentHistoryModel extends BaseModel {

    constructor() {
        super({
                name: "visit_order_payment",
                tableName: 'visit_order',
                label: "Payment history",
                // display_key: 'code'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                code: {
                    name: 'code',
                    type: "String",
                    label: 'Transaction ID',
                    filter: {type: 'input', match: 'contains'},
                    class:"left",

                },

                property_id_key: {
                    name: 'property_id_key',
                    type: "String",
                    label: 'Property ID',
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    computed: (data) => {
                        data.property_id_key = '';
                    },
                },

                property_name_key: {
                    name: 'property_name_key',
                    type: "String",
                    label: 'Property',
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    computed: (data) => {
                        data.property_name_key = '';
                    },
                },
                uid_key: {
                    name: "uid_key",
                    label: "User",
                    type: "Computed",
                    custom: 'SelectionText',
                    models: new UserModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    computed: (data) => {
                        data.uid_key = '';
                    },
                },
                items : {
                    name: 'items',
                    type: "String",
                    label: 'Item(s)',
                    custom: 'SelectionText',
                    filter: {type: 'input', match: 'contains'},
                    class:"right",
                    computed: (data) => {
                        data.items = '';
                    },
                },
                total_payment: {
                    name: 'total_payment',
                    type: "String",
                    label: 'Amount',
                    class:"right",
                },
                created_at : {
                    name: 'created_at',
                    type: "Datetime",
                    label: 'Created date',
                    filter: { type: 'DateRange', match: 'contains'}
                },
                // status: {
                //     name: 'status',
                //     type: "String",
                //     label: 'Payment status',
                // },

                status: {
                    name: "status",
                    label: "Payment status",
                    type: "Selection",
                    custom: 'SelectionText',
                    width : 10,
                    options: [
                        {value: 'ST001', label: 'waitting',style: 'color:  #000000;'},
                        {value: 'ST002', label: 'Paid',style: 'color:  #000000;'},
                        {value: 'ST003', label: 'Failed',style: 'color:  #000000;'},
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"left",
                },
                total_invoice: {
                    name: 'total_invoice',
                    type: "String",
                    label: 'Invoices',
                    custom: 'SelectionText',
                    class:"center",

                },
                detail_get: {
                    refs:  'property { name id code } user {id fullname} total_visit_order_item id',
                    display: false
                },

            })
    }

}