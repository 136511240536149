import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class AvailableModel extends BaseModel {

    constructor() {
        super({ 
                name: "available",
                tableName: 'available',
                label: "Available", 
                default_order: { key: 'updated_at', direction: 'desc' } }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            visit: {
                refs: "visit {id departure arrival week_number visit_start visit_end}",
                display: false
            },
            property: {
                refs: "property {id code name}",
                display: false
            },
            owner: {
                refs: "owner {id fullname}",
                display: false
            },
            week: {
                refs: "week{visit_start visit_end}",
                display: false
            },
            update_user: {
                refs: "update_user {id fullname}",
                display: false
            },
            create_user: {
                refs: "create_user {id fullname}",
                display: false
            },
            type: {
                name: 'type',
                type: "Selection",
                label: 'Type',
                options: [
                    {
                        value: '3',
                        label: 'Rent'
                    },
                    {
                        value: '2',
                        label: 'Exchange'
                    }
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            status: {
                name: 'status',
                type: "Selection",
                // custom: "SelectionText",
                label: 'Status',
                options: [
                    {
                        value: 'ST001',
                        label: 'Request'
                    },
                    {
                        value: 'ST002',
                        label: 'Accepted'
                    },
                    {
                        value: 'ST003',
                        label: 'Cancelled'
                    },
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            property_code: {
                name: 'property_code',
                type: "String",
                label: 'Property ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.property_code = '';
                },
            },
            owner_name: {
                name: 'owner_name',
                type: "String",
                label: 'Owner',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.owner_name = '';
                },
            },
            visit_week: {
                name: 'visit_week',
                type: "String",
                label: 'Week',
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.visit_week = '';
                },
            },
            want_to_exchange: {
                name: 'want_to_exchange',
                type: "String",
                label: 'Want to exchange with',
                computed: (data) => {
                    data.want_to_exchange = '';
                },
            },
            my_property: { 
                name: 'my_property', 
                type: "Selection",
                custom: "SelectionText",
                label: "Only for owner's property",
                options: [
                    {
                        value: true,
                        label: 'Yes'
                    },
                    {
                        value: false,
                        label: 'No'
                    }
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            active: { 
                name: 'active', 
                type: "Selection",
                custom: "SelectionText",
                options: [
                    {
                        value: true,
                        label: 'Yes'
                    },
                    {
                        value: false,
                        label: 'No'
                    }
                ],
                label: 'Active',
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            updated_name : { 
                name: 'updated_name', 
                type: "String",
                label: 'Updated by',
                computed: (data) => {
                    data.updated_name = '';
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Updated date'
            }
        },)
    }

}